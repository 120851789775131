<template>
  <div
    class="flex flex-col mx-auto text-left"
    v-if="isAuthenticated === 'unauthorized'"
  >
    <form @submit.prevent="register(email, password)" class="reg-form">
      <h1 class="mb-8 text-3xl">Register</h1>
      <div class="relative">
        <input
          type="text"
          placeholder="Email"
          v-model="email"
          id="email"
          class="block w-full px-4 py-2 placeholder-transparent mb-7 form-input peer"
        />
        <label
          for="email"
          class="absolute left-0 text-sm transition-all -top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:top-2"
          >Email address</label
        >

        <input
          type="password"
          placeholder="Password"
          v-model="password"
          id="password"
          class="block w-full px-4 py-2 mb-2 placeholder-transparent form-input peer"
        />
        <label
          for="password"
          class="absolute left-0 text-sm transition-all top-12 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:top-20"
          >Password</label
        >
      </div>
      <button
        class="px-4 py-2 mr-2 text-lg text-white bg-green-700 rounded hover:opacity-75"
      >
        Register
      </button>
      <p class="mt-4">
        Have an account?
        <router-link to="/login" class="ml-3 text-gray-500 hover:underline"
          >Login Here</router-link
        >
      </p>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import { isAuthenticated, register } from "../firebase.js";

export default {
  setup() {
    const email = ref("");
    const password = ref("");

    return {
      email,
      password,
      isAuthenticated,
      register,
    };
  },
};
</script>
